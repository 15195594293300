<template>
  <q-page padding>
    <header-advertiser />
    <list-advertiser />
    <!-- <form-advertiser v-if="dialogAdvertiser"/> -->
  </q-page>
</template>

<script>
import HeaderAdvertiser from '../components/advertiser/HeaderAdvertiser.vue';
// import FormAdvertiser from "../components/advertiser/FormAdvertiser.vue";
import ListAdvertiser from '../components/advertiser/ListAdvertiser.vue';
import AdvertiserMixin from '../mixins/AdvertiserMixin';

export default {
  // FormAdvertiser
  components: { ListAdvertiser, HeaderAdvertiser },
  name: 'PageAdvertiser',
  mixins: [AdvertiserMixin]
};
</script>
