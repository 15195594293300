import { render, staticRenderFns } from "./ListAdvertiser.vue?vue&type=template&id=e240fd8e"
import script from "./ListAdvertiser.vue?vue&type=script&lang=js"
export * from "./ListAdvertiser.vue?vue&type=script&lang=js"
import style1 from "./ListAdvertiser.vue?vue&type=style&index=1&id=e240fd8e&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtn,QSelect,QItem,QItemSection,QItemLabel,QToggle,QPagination,QTd,QIcon,QTooltip,QChip});
