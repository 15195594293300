<template>
  <div>
    <q-toolbar class="q-pr-none column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> Advertisers </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('advertiser')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-input outlined v-model="search" dense type="text" :label="$t('search')">
        <template v-slot:prepend>
          <q-icon name="eva-search-outline" />
        </template>
      </q-input>
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter') }} advertisers </q-tooltip></q-btn
      >
      <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="exportTableCsv(prepareItemCSV(advertisers), prepareColumnCSV(columns), 'advertiser')"
        unelevated
      >
        <q-tooltip> {{ $t('export_csv') }} </q-tooltip>
      </q-btn>
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        :label="$t('add')"
        no-caps
        to="/advertiser/create"
      />
      <!-- @click="ActionSetDialogAdvertiser(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-1">
          <q-input outlined v-model="params.id" type="text" label="Id" />
        </div>

        <div class="col-2">
          <q-select
            outlined
            label="Nível"
            option-value="value"
            option-label="label"
            :options="[
              { value: 1, label: 'Bronze' },
              { value: 2, label: 'Prata' },
              { value: 3, label: 'Ouro' },
              { value: null, label: 'Sem nível' }
            ]"
            v-model="params.level"
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                <q-item-section>
                  {{ scope.opt.label }}
                </q-item-section>
                <q-item-section avatar>
                  <q-icon name="mdi-medal-outline" size="sm" :style="iconStyle(scope.opt.value)" />
                </q-item-section>
              </q-item>
            </template>
            
            <template v-slot:append>
              <q-icon :name="params.level ? 'mdi-medal-outline' : ''" size="sm" :style="params.level ? iconStyle(params.level.value) : ''"/>
            </template>
          </q-select>
        </div>

        <div class="col-2">
          <q-input outlined mask="##.###.###/####-##" unmasked-value label="CNPJ" v-model="params.cnpj" type="text" />
        </div>
        <div class="col-3">
          <q-select
            use-input
            clearable
            input-debounce="0"
            @filter="filterBusinessName"
            v-model="params.businessName"
            :options="nameAdvertisers"
            :label="$t('corporate_name')"
            outlined
          />
        </div>
        <div class="col-4">
          <span class="text-weight-medium absolute" style="top: -5px">{{ $t('billing_deadline') }}</span>
          <div class="row q-col-gutter-sm">
            <div class="col">
              <q-select
                v-model="params.prazo_faturamento"
                :options="[$t('greater_than'), $t('equal_to'), $t('less_than')]"
                outlined
                :label="$t('deadline')"
              />
            </div>
            <div class="col">
              <q-input
                outlined
                v-model="filter.qtd_dias"
                type="text"
                :label="$t('number_of_days')"
                class="full-width"
              />
            </div>
          </div>
        </div>
        <div class="col-2">
          <q-select
            :label="$t('type')"
            clearable
            v-model="params.type"
            :options="[$t('advertisers'), $t('agencies')]"
            outlined
          />
        </div>
        <div class="col-3">
          <q-select
            use-input
            clearable
            input-debounce="0"
            @filter="filterTrademark"
            v-model="params.trademark"
            :options="nameTrademarks"
            :label="$t('fantasy_name')"
            outlined
          />
        </div>
        <div class="col-3">
          <q-select
            clearable
            :label="$t('nationality')"
            v-model="params.nationality"
            :options="['Nacional', 'Internacional']"
            outlined
          />
        </div>
        <div class="col-4">
          <div class="q-gutter-lg">
            <div>
              <span class="text-weight-medium">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.status.activated" label="Ativados" color="primary" />
                <q-checkbox v-model="params.status.disabled" label="Desativados" color="primary" />
                <q-checkbox v-model="params.status.paused" label="Pausados" color="primary" />
                <q-checkbox v-model="params.status.pending" label="Pendentes" color="primary" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterAdvertisers" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import AdvertiserMixins from '../../mixins/AdvertiserMixin';
export default {
  name: 'HeaderAdvertiser',
  mixins: [AdvertiserMixins],
  data() {
    return {
      filter: true,
      params: {
        id: null,
        cnpj: null,
        businessName: null,
        trademark: null,
        nationality: null,
        type: null,
        status: {
          activated: false,
          disabled: false,
          paused: false,
          pending: false
        },
        level: null
      },
      nameAdvertisers: [],
      nameTrademarks: [],
      search: null
    };
  },
  watch: {
    advertisersFull() {
      this.nameAdvertisers = this.advertisersAll.map((item) => item.business_name);
      this.nameTrademarks = this.advertisersAll.map((item) => item.trademark);
    },
    search() {
      this.ActionSetFilterAdvertiser(this.search);
    }
  },
  methods: {
    clearFilter() {
      this.params = {
        id: null,
        cnpj: null,
        prazo_faturamento: null,
        businessName: null,
        trademark: null,
        nationality: null,
        type: null,
        status: {
          activated: false,
          disabled: false,
          paused: false,
          pending: false
        }
      };
      this.search = null;
      this.ActionSetAdvertisers(this.advertisersAll);
    },
    onFilterAdvertisers() {
      this.search = null;
      let advertisers = this.advertisersAll;

      if (this.params.cnpj) advertisers = advertisers.filter((item) => item.cnpj == this.params.cnpj);

      if (this.params.id) advertisers = advertisers.filter((item) => item.id == this.params.id);

      if (this.params.level) advertisers = advertisers.filter((item) => item.level == this.params.level.value);

      if (this.params.businessName)
        advertisers = advertisers.filter((item) => item.business_name == this.params.businessName);

      if (this.params.trademark) advertisers = advertisers.filter((item) => item.trademark == this.params.trademark);

      if (this.params.nationality) {
        if (this.params.nationality == 'Nacional') advertisers = advertisers.filter((item) => item.country == 'Brasil');
        else advertisers = advertisers.filter((item) => item.country != 'Brasil');
      }

      if (this.params.type) {
        let type = this.params.type == this.$t('agencies') ? 'agencia' : 'anunciante';
        advertisers = advertisers.filter((item) => item.type == type);
      }

      let status = [];
      if (this.params.status.pending) status.push(0);
      if (this.params.status.activated) status.push(1);
      if (this.params.status.paused) status.push(2);
      if (this.params.status.disabled) status.push(3);

      if (status.length > 0) advertisers = advertisers.filter((item) => status.includes(item.status));

      this.ActionSetAdvertisers(advertisers);
    },

    filterBusinessName(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.nameAdvertisers = this.advertisersAll
          .sort((a, b) => a.business_name.localeCompare(b.business_name))
          .map((item) => item.business_name)
          .filter((v) => v.toLocaleLowerCase().indexOf(needle) > -1);
      });
      return;
    },
    filterTrademark(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.nameTrademarks = this.advertisersAll
          .sort((a, b) => a.trademark.localeCompare(b.trademark))
          .map((item) => item.trademark)
          .filter((v) => v.toLocaleLowerCase().indexOf(needle) > -1);
      });
      return;
    }
  }
};
</script>

<style></style>
